import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/settori.css'
import '../css/aos.css'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const SettoriPage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				className: 'transition-support webkit settori'
      }}
      title="Settori | Studio Legale Cirio - Francescon - Stella - Sartori - Maccari"
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="settori">
				<div className="content clearfix">

					<div className="section-block intro-title-2 header-image redFilter"></div>

					<div className="row">
						<div className="underHeader">
							<div className="item">
								<h1 data-aos = "fade-up" ><FormattedMessage id="settoriMainTitle" /></h1>
								{/* <p data-aos = "fade-up" data-aos-duration = "1000"><FormattedMessage id="settoriMainSubtitle" /></p> */}
							</div>
						</div>
					</div>

					<div className="section-block hero-5 famiglia color" id="famiglia">
						<div className="media-column width-4 img-center">
							<img data-aos = "fade-right" src={require('../images/img/settori/famiglia.png')} />
						</div>
						<div className="row ">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left"><FormattedMessage id="settoriTitle1" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration = "1000" className="italic "><FormattedMessage id="settoriSubtitle1" /></p> */}
										<p data-aos = "fade-left" dara-aos-duration = "1500" ><FormattedHTMLMessage id="settoriText1.1" /></p>

										<ul data-aos = "fade-left"><FormattedHTMLMessage id="settoriText1.2" /></ul>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 contenzioso" id="contenzioso">
						<div className="media-column width-4 img-center">
							<img data-aos = "fade-right" src={require('../images/img/settori/contenzioso.png')} />
						</div>
						<div className="row">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left"><FormattedMessage id="settoriTitle2" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration="500" className="italic"><FormattedMessage id="settoriSubtitle2" /></p> */}
										<p data-aos = "fade-left" data-aos-duration="800" ><FormattedHTMLMessage id="settoriText2.1" /></p>
										<p data-aos = "fade-left" data-aos-duration="800"><FormattedHTMLMessage id="settoriText2.2" /></p>
										<p data-aos = "fade-left" data-aos-duration="1100"><FormattedHTMLMessage id="settoriText2.3" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 bancario color" id="bancario">
						<div className="media-column width-4 img-center">
							<img data-aos = "fade-right" src={require('../images/img/settori/diritto-bancario.png')} />
						</div>
						<div className="row ">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left"><FormattedMessage id="settoriTitle3" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration="500" className="italic"><FormattedMessage id="settoriSubtitle3" /></p> */}
										<p data-aos = "fade-left" data-aos-duration="800"><FormattedHTMLMessage id="settoriText3.1" /></p>
										<p data-aos = "fade-left" data-aos-duration="1100"><FormattedHTMLMessage id="settoriText3.2" /></p>
										<p data-aos = "fade-left" data-aos-duration="1400"><FormattedHTMLMessage id="settoriText3.3" /></p>
										<ul><FormattedHTMLMessage id="settoriText3.4" /></ul>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 penale" id="penale">
						<div className="media-column width-4 img-center">
							<img data-aos = "fade-right" src={require('../images/img/settori/diritto-penale.png')} />
						</div>
						<div className="row ">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left"><FormattedMessage id="settoriTitle4" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration="500" className="italic"><FormattedMessage id="settoriSubtitle4" /></p> */}
										<p data-aos = "fade-left" data-aos-duration="800"><FormattedHTMLMessage id="settoriText4.1" /></p>
										<p data-aos = "fade-left" data-aos-duration="800"><FormattedHTMLMessage id="settoriText4.2" /></p>
		                                <ul><FormattedHTMLMessage id="settoriText4.3" /></ul>
										<p data-aos = "fade-left" data-aos-duration="800"><FormattedMessage id="settoriText4.4" /></p>
										<p data-aos = "fade-left" data-aos-duration="800"><FormattedMessage id="settoriText4.5" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 amministrativo color" id="amministrativo">
						<div className="media-column width-4 img-center">
							<img data-aos = "fade-right" src={require('../images/img/settori/diritto-amministrativo.png')} />
						</div>
						<div className="row ">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left"><FormattedMessage id="settoriTitle5" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration="500" className="italic"><FormattedMessage id="settoriSubtitle5" /></p> */}
										<p data-aos = "fade-left" data-aos-duration="700"><FormattedHTMLMessage id="settoriText5.1" /></p>
										<p data-aos = "fade-left" data-aos-duration="1000"><FormattedHTMLMessage id="settoriText5.2" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 fallimentare" id="fallimentare">
						<div className="media-column width-4 img-center">
							<img  data-aos = "fade-right" src={require('../images/img/settori/diritto-fallimentare.png')} />
						</div>
						<div className="row ">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left" data-aos-duration="500"><FormattedMessage id="settoriTitle6" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration="800" className="italic"><FormattedMessage id="settoriSubtitle6" /></p> */}
										<p data-aos = "fade-left" data-aos-duration="1000"><FormattedHTMLMessage id="settoriText6.1" /></p>
										<p data-aos = "fade-left" data-aos-duration="1200"><FormattedHTMLMessage id="settoriText6.2" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 crediti color" id="crediti">
						<div className="media-column width-4 img-center">
							<img data-aos = "fade-right" src={require('../images/img/settori/recupero-crediti.png')} />
						</div>
						<div className="row ">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left"><FormattedMessage id="settoriTitle7" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration="500" className="italic"><FormattedMessage id="settoriSubtitle7" /></p> */}
										<p data-aos = "fade-left" data-aos-duration="700"><FormattedHTMLMessage id="settoriText7.1" /></p>
										<p data-aos = "fade-left" data-aos-duration="900"><FormattedHTMLMessage id="settoriText7.2" /></p>
										<p data-aos = "fade-left" data-aos-duration="1000"><FormattedHTMLMessage id="settoriText7.3" /></p>
										<p data-aos = "fade-left" data-aos-duration="1200"><FormattedHTMLMessage id="settoriText7.4" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="section-block hero-5 contrattualistica" id="contrattualistica">
						<div className="media-column width-4 img-center">
							<img data-aos = "fade-right" src={require('../images/img/settori/contrattualistica.png')} />
						</div>
						<div className="row ">
							<div className="column width-7 offset-5 sec-desc">
								<div className="hero-content split-hero-content ">
									<div className="hero-content-inner ">
										<h4 data-aos = "fade-left"><FormattedMessage id="settoriTitle8" /></h4>
										{/* <p data-aos = "fade-left" data-aos-duration="500" className="italic"><FormattedMessage id="settoriSubtitle8" /></p> */}
										<p data-aos = "fade-left" data-aos-duration="800"><FormattedHTMLMessage id="settoriText8.1" /></p>
										<p data-aos = "fade-left" data-aos-duration="1000"><FormattedHTMLMessage id="settoriText8.2" /></p>
									</div>
								</div>
							</div>
						</div>
					</div>



				</div>
			</div>
		</Layout >
	</>
)

export default SettoriPage

